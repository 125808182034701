exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-visit-banjul-js": () => import("./../../../src/pages/visit-banjul.js" /* webpackChunkName: "component---src-pages-visit-banjul-js" */),
  "component---src-pages-visit-crocodile-pool-js": () => import("./../../../src/pages/visit-crocodile-pool.js" /* webpackChunkName: "component---src-pages-visit-crocodile-pool-js" */),
  "component---src-pages-visit-kunta-kinteh-island-js": () => import("./../../../src/pages/visit-kunta-kinteh-island.js" /* webpackChunkName: "component---src-pages-visit-kunta-kinteh-island-js" */),
  "component---src-pages-visit-monkey-forrest-js": () => import("./../../../src/pages/visit-monkey-forrest.js" /* webpackChunkName: "component---src-pages-visit-monkey-forrest-js" */),
  "component---src-pages-visit-pelican-island-js": () => import("./../../../src/pages/visit-pelican-island.js" /* webpackChunkName: "component---src-pages-visit-pelican-island-js" */),
  "component---src-pages-visit-serekunda-market-js": () => import("./../../../src/pages/visit-serekunda-market.js" /* webpackChunkName: "component---src-pages-visit-serekunda-market-js" */),
  "component---src-pages-visit-tanji-fishing-town-js": () => import("./../../../src/pages/visit-tanji-fishing-town.js" /* webpackChunkName: "component---src-pages-visit-tanji-fishing-town-js" */),
  "component---src-pages-visit-the-gambian-reptiles-farm-js": () => import("./../../../src/pages/visit-the-gambian-reptiles-farm.js" /* webpackChunkName: "component---src-pages-visit-the-gambian-reptiles-farm-js" */)
}

